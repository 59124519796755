import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { getApiClient } from "@/services/api";
import Store from "../index";
import {
    AttendeeDataObject,
    AnalyticsFavoriteItem,
    AnalyticsVideoStreamItem,
    AnalyticsExhibitor,
    AnalyticsSessionStub,
    AnalyticsCeQuestionUser,
    AnalyticsSessionWithCeQuestions,
    LogOutput,
    AnalyticsExhibitorOverview,
    AnalyticsPoster,
    AnalyticsPosterOverview,
    AnalyticsLivestream,
    AnalyticsSpeakeasyItem,
    AnalyticsLivestreamVisitor,
    AnalyticsPollOverview,
    AnalyticsPollSingle,
    AnalyticsDateRange,
    AnalyticsVisitorWithHits,
    AnalyticsConversation,
    AnalyticsMessageDay,
    AnalyticsAppointmentData
} from "@/types/interfaces";

const analyticsBase = "/log/analytics";

interface AnalyticsDataRequestParams {
    reportType: string;
    mutation?: string;
    sessionId?: string;
    companyId?: string;
    attendeeId?: string;
    start?: string;
    end?: string;
}

interface AnalyticsExhibitorData {
    exhibVisitors: Array<AnalyticsExhibitorOverview>;
    landingUsers: Array<AnalyticsVisitorWithHits>;
}

interface AnalyticsPosterData {
    landingVisitors: Array<AnalyticsPosterOverview>;
    landingUsers: Array<AnalyticsVisitorWithHits>;
}

@Module({
    dynamic: true,
    store: Store,
    name: "AdminAnalyticsModule",
    namespaced: true
})
export default class AdminAnalyticsModule extends VuexModule {
    reportDates = {
        start: "",
        end: ""
    } as AnalyticsDateRange;
    loggedIn = [] as Array<AttendeeDataObject>;
    notLoggedIn = [] as Array<AttendeeDataObject>;
    favorites = [] as Array<AnalyticsFavoriteItem>;
    videoStreams = [] as Array<AnalyticsVideoStreamItem>;
    exhibitorData: AnalyticsExhibitorData = {
        exhibVisitors: [],
        landingUsers: []
    };
    exhibitorVisits = [] as Array<AnalyticsExhibitorOverview>;
    sessionsWithPolls = [] as Array<AnalyticsSessionStub>;
    sessionsWithCeQuestions = [] as Array<AnalyticsSessionWithCeQuestions>;
    ceQuestionData = [] as Array<AnalyticsCeQuestionUser>;
    userTimelineData = [] as Array<LogOutput>;
    speakeasies = [] as Array<AnalyticsSpeakeasyItem>;
    polls = [] as Array<AnalyticsPollOverview>;
    messages = [] as Array<AnalyticsVisitorWithHits>;
    conversations = [] as Array<AnalyticsConversation>;
    messagesByDate = [] as Array<AnalyticsMessageDay>;
    singleExhibitor: AnalyticsExhibitor = {
        sponsorId: "",
        sponsorName: "",
        users: []
    };
    singlePoster: AnalyticsPoster = {
        posterId: "",
        posterName: "",
        users: []
    };
    singleLivestream: AnalyticsLivestream = {
        sessionId: "",
        sessionName: "",
        users: []
    };
    singleSpeakeasy: AnalyticsLivestream = {
        sessionId: "",
        sessionName: "",
        users: []
    };
    posterData: AnalyticsPosterData = {
        landingUsers: [],
        landingVisitors: []
    };
    singlePoll: AnalyticsPollSingle = {
        sessionName: "",
        users: [],
        pollData: []
    };
    appointmentData: AnalyticsAppointmentData = {
        total: 0,
        confirmed: 0,
        virtApptsWithMeetingTime: 0,
        locationTypes: {}
    };
    loading = false;

    @Mutation
    public setLoading(data: boolean) {
        this.loading = data;
    }

    @Mutation
    public setLoggedIn(data: Array<AttendeeDataObject>) {
        this.loggedIn = data;
    }

    @Mutation
    public setNotLoggedIn(data: Array<AttendeeDataObject>) {
        this.notLoggedIn = data;
    }

    @Mutation
    public setFavorites(data: Array<AnalyticsFavoriteItem>) {
        this.favorites = data;
    }

    @Mutation
    public setVideoStreams(data: Array<AnalyticsVideoStreamItem>) {
        this.videoStreams = data;
    }

    @Mutation
    public setExhibitorVisits(data: Array<AnalyticsExhibitorOverview>) {
        this.exhibitorVisits = data;
    }

    @Mutation
    public setSessionsWithPolls(data: Array<AnalyticsSessionStub>) {
        this.sessionsWithPolls = data;
    }

    @Mutation
    public setSessionsWithCeQuestions(
        data: Array<AnalyticsSessionWithCeQuestions>
    ) {
        this.sessionsWithCeQuestions = data;
    }

    @Mutation
    public setCeQuestionData(data: Array<AnalyticsCeQuestionUser>) {
        this.ceQuestionData = data;
    }

    @Mutation
    public setUserTimelineData(data: Array<LogOutput>) {
        this.userTimelineData = data;
    }

    @Mutation
    public setSingleExhibitor(data: AnalyticsExhibitor) {
        this.singleExhibitor = data;
    }

    @Mutation
    public setSinglePoster(data: AnalyticsPoster) {
        this.singlePoster = data;
    }

    @Mutation
    public setPosterData(data: AnalyticsPosterData) {
        this.posterData = data;
    }

    @Mutation
    public setSingleLivestream(data: AnalyticsLivestream) {
        this.singleLivestream = data;
    }

    @Mutation
    public setSpeakeasies(data: Array<AnalyticsSpeakeasyItem>) {
        this.speakeasies = data;
    }

    @Mutation
    public setSingleSpeakeasy(data: AnalyticsLivestream) {
        this.singleSpeakeasy = data;
    }

    @Mutation
    public setReportDates(data: AnalyticsDateRange) {
        this.reportDates = data;
    }

    @Mutation
    public setPolls(data: Array<AnalyticsPollOverview>) {
        this.polls = data;
    }

    @Mutation
    public setPollSingle(data: AnalyticsPollSingle) {
        this.singlePoll = data;
    }

    @Mutation
    public setExhibitorData(data: AnalyticsExhibitorData) {
        this.exhibitorData = data;
    }

    @Mutation
    public setMessages(data: Array<AnalyticsVisitorWithHits>) {
        this.messages = data;
    }

    @Mutation
    public setConversations(data: Array<AnalyticsConversation>) {
        this.conversations = data;
    }

    @Mutation
    public setMessagesByDate(data: Array<AnalyticsMessageDay>) {
        this.messagesByDate = data;
    }

    @Mutation
    public setAppointmentData(data: AnalyticsAppointmentData) {
        this.appointmentData = data;
    }

    @Action({})
    async getAnalyticsData(payload: AnalyticsDataRequestParams) {
        this.context.commit("setLoading", true);

        const token = this.context.rootGetters.idToken;
        const headers = {
            Authorization: `bearer ${token}`
        };
        const postData = {
            ...payload
        } as AnalyticsDataRequestParams;

        if (this.reportDates.start) {
            postData.start = this.reportDates.start;
        }

        if (this.reportDates.end) {
            postData.end = this.reportDates.end;
        }

        delete postData.mutation;

        getApiClient()
            .post(analyticsBase, postData, { headers })
            .then((response) => {
                const data = response.data ? response.data : [];

                if (payload.mutation) {
                    this.context.commit(payload.mutation, data);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                this.context.commit("setLoading", false);
            });
    }

    @Action({})
    async getAttendeeLoginStatus() {
        this.context.commit("setLoading", true);

        const token = this.context.rootGetters.idToken;
        const headers = {
            Authorization: `bearer ${token}`
        };

        const postData = {
            reportType: "getUserLoginStatus"
        } as AnalyticsDataRequestParams;

        if (this.reportDates.start) {
            postData.start = this.reportDates.start;
        }

        if (this.reportDates.end) {
            postData.end = this.reportDates.end;
        }

        getApiClient()
            .post(`${analyticsBase}`, postData, { headers })
            .then((response) => {
                if (response.data) {
                    const loggedIn =
                        response.data.loggedIn &&
                        Array.isArray(response.data.loggedIn)
                            ? response.data.loggedIn
                            : [];
                    const notLoggedIn =
                        response.data.notLoggedIn &&
                        Array.isArray(response.data.notLoggedIn)
                            ? response.data.notLoggedIn
                            : [];
                    this.context.commit("setLoggedIn", loggedIn);
                    this.context.commit("setNotLoggedIn", notLoggedIn);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                this.context.commit("setLoading", false);
            });
    }

    @Action({})
    async getFavorites() {
        await this.context.dispatch("getAnalyticsData", {
            reportType: "getFavorites",
            mutation: "setFavorites"
        });
    }

    @Action({})
    async getVideoStreams() {
        await this.context.dispatch("getAnalyticsData", {
            reportType: "getVideoStreamTotals",
            mutation: "setVideoStreams"
        });
    }

    @Action({})
    async getSingleLivestream(sessionId: string) {
        await this.context.dispatch("getAnalyticsData", {
            reportType: "getVideoStreamTotals",
            mutation: "setSingleLivestream",
            sessionId
        });
    }

    @Action({})
    async getExhibitorData() {
        await this.context.dispatch("getAnalyticsData", {
            reportType: "getExhibitorVisits",
            mutation: "setExhibitorData"
        });
    }

    @Action({})
    async getSingleExhibitorVisits(companyId: string) {
        await this.context.dispatch("getAnalyticsData", {
            reportType: "getExhibitorVisits",
            mutation: "setSingleExhibitor",
            companyId: companyId
        });
    }

    @Action({})
    async getPosterVisits() {
        await this.context.dispatch("getAnalyticsData", {
            reportType: "getPosterVisits",
            mutation: "setPosterData"
        });
    }

    @Action({})
    async getSinglePosterVisits(posterId: string) {
        await this.context.dispatch("getAnalyticsData", {
            reportType: "getPosterVisits",
            mutation: "setSinglePoster",
            posterId: posterId
        });
    }

    @Action({})
    async getSessionsWithPolls() {
        await this.context.dispatch("getAnalyticsData", {
            reportType: "getSessionsWithPolls",
            mutation: "setSessionsWithPolls"
        });
    }

    @Action({})
    async getSessionsWithCeQuestions() {
        await this.context.dispatch("getAnalyticsData", {
            reportType: "getSessionsWithCeQuestions",
            mutation: "setSessionsWithCeQuestions"
        });
    }

    @Action({})
    async getCeQuestionData(sessionId: string) {
        await this.context.dispatch("getAnalyticsData", {
            reportType: "getCeQuestionsForSession",
            mutation: "setCeQuestionData",
            sessionId: sessionId
        });
    }

    @Action({})
    async getUserTimeline(attendeeId: string) {
        await this.context.dispatch("getAnalyticsData", {
            reportType: "getUserTimeline",
            mutation: "setUserTimelineData",
            attendeeId: attendeeId
        });
    }

    @Action({})
    async getSpeakeasies() {
        await this.context.dispatch("getAnalyticsData", {
            reportType: "getSpeakeasyData",
            mutation: "setSpeakeasies"
        });
    }

    @Action({})
    async getSingleSpeakeasy(sessionId: string) {
        await this.context.dispatch("getAnalyticsData", {
            reportType: "getSpeakeasyData",
            mutation: "setSingleSpeakeasy",
            sessionId: sessionId
        });
    }

    @Action({})
    async getPolls() {
        await this.context.dispatch("getAnalyticsData", {
            reportType: "getPollData",
            mutation: "setPolls"
        });
    }

    @Action({})
    async getPollSingle(sessionId: string) {
        await this.context.dispatch("getAnalyticsData", {
            reportType: "getPollData",
            mutation: "setPollSingle",
            sessionId: sessionId
        });
    }

    @Action({})
    async getMessageData() {
        this.context.commit("setLoading", true);

        const token = this.context.rootGetters.idToken;
        const headers = {
            Authorization: `bearer ${token}`
        };

        const postData = {
            reportType: "getMessageData"
        } as AnalyticsDataRequestParams;

        if (this.reportDates.start) {
            postData.start = this.reportDates.start;
        }

        if (this.reportDates.end) {
            postData.end = this.reportDates.end;
        }

        getApiClient()
            .post(`${analyticsBase}`, postData, { headers })
            .then((response) => {
                if (response.data) {
                    this.context.commit("setMessages", response.data.users);
                    this.context.commit(
                        "setConversations",
                        response.data.conversations
                    );
                    this.context.commit(
                        "setMessagesByDate",
                        response.data.dates
                    );
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                this.context.commit("setLoading", false);
            });
    }

    @Action({})
    async getAppointmentData() {
        await this.context.dispatch("getAnalyticsData", {
            reportType: "getAppointmentData",
            mutation: "setAppointmentData"
        });
    }

    @Action({})
    async updateReportDates(data: AnalyticsDateRange) {
        this.context.commit("setReportDates", data);
    }
}
